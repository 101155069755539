define("ember-phoenix/index", ["module", "exports"], function (module, exports) {
  "use strict";

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  !function (e, t) {
    "object" == (typeof exports === "undefined" ? "undefined" : _typeof(exports)) && "object" == (typeof module === "undefined" ? "undefined" : _typeof(module)) ? module.exports = t() : "function" == typeof define && define.amd ? define([], t) : "object" == (typeof exports === "undefined" ? "undefined" : _typeof(exports)) ? exports.Phoenix = t() : e.Phoenix = t();
  }(undefined, function () {
    return function (e) {
      var t = {};function n(i) {
        if (t[i]) return t[i].exports;var o = t[i] = { i: i, l: !1, exports: {} };return e[i].call(o.exports, o, o.exports, n), o.l = !0, o.exports;
      }return n.m = e, n.c = t, n.d = function (e, t, i) {
        n.o(e, t) || Object.defineProperty(e, t, { enumerable: !0, get: i });
      }, n.r = function (e) {
        "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, { value: "Module" }), Object.defineProperty(e, "__esModule", { value: !0 });
      }, n.t = function (e, t) {
        if (1 & t && (e = n(e)), 8 & t) return e;if (4 & t && "object" == (typeof e === "undefined" ? "undefined" : _typeof(e)) && e && e.__esModule) return e;var i = Object.create(null);if (n.r(i), Object.defineProperty(i, "default", { enumerable: !0, value: e }), 2 & t && "string" != typeof e) for (var o in e) {
          n.d(i, o, function (t) {
            return e[t];
          }.bind(null, o));
        }return i;
      }, n.n = function (e) {
        var t = e && e.__esModule ? function () {
          return e.default;
        } : function () {
          return e;
        };return n.d(t, "a", t), t;
      }, n.o = function (e, t) {
        return Object.prototype.hasOwnProperty.call(e, t);
      }, n.p = "", n(n.s = 0);
    }([function (e, t, n) {
      (function (t) {
        e.exports = t.Phoenix = n(2);
      }).call(this, n(1));
    }, function (e, t) {
      var n;n = function () {
        return this;
      }();try {
        n = n || Function("return this")() || (0, eval)("this");
      } catch (e) {
        "object" == (typeof window === "undefined" ? "undefined" : _typeof(window)) && (n = window);
      }e.exports = n;
    }, function (e, t, n) {
      "use strict";
      function i(e) {
        return function (e) {
          if (Array.isArray(e)) {
            for (var t = 0, n = new Array(e.length); t < e.length; t++) {
              n[t] = e[t];
            }return n;
          }
        }(e) || function (e) {
          if (Symbol.iterator in Object(e) || "[object Arguments]" === Object.prototype.toString.call(e)) return Array.from(e);
        }(e) || function () {
          throw new TypeError("Invalid attempt to spread non-iterable instance");
        }();
      }function o(e) {
        return (o = "function" == typeof Symbol && "symbol" == _typeof(Symbol.iterator) ? function (e) {
          return typeof e === "undefined" ? "undefined" : _typeof(e);
        } : function (e) {
          return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e === "undefined" ? "undefined" : _typeof(e);
        })(e);
      }function r(e, t) {
        return function (e) {
          if (Array.isArray(e)) return e;
        }(e) || function (e, t) {
          var n = [],
              i = !0,
              o = !1,
              r = void 0;try {
            for (var s, a = e[Symbol.iterator](); !(i = (s = a.next()).done) && (n.push(s.value), !t || n.length !== t); i = !0) {}
          } catch (e) {
            o = !0, r = e;
          } finally {
            try {
              i || null == a.return || a.return();
            } finally {
              if (o) throw r;
            }
          }return n;
        }(e, t) || function () {
          throw new TypeError("Invalid attempt to destructure non-iterable instance");
        }();
      }function s(e, t) {
        if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
      }function a(e, t) {
        for (var n = 0; n < t.length; n++) {
          var i = t[n];i.enumerable = i.enumerable || !1, i.configurable = !0, "value" in i && (i.writable = !0), Object.defineProperty(e, i.key, i);
        }
      }function c(e, t, n) {
        return t && a(e.prototype, t), n && a(e, n), e;
      }n.r(t), n.d(t, "Channel", function () {
        return j;
      }), n.d(t, "Serializer", function () {
        return R;
      }), n.d(t, "Socket", function () {
        return C;
      }), n.d(t, "LongPoll", function () {
        return T;
      }), n.d(t, "Ajax", function () {
        return w;
      }), n.d(t, "Presence", function () {
        return E;
      });var u = "undefined" != typeof self ? self : null,
          h = "undefined" != typeof window ? window : null,
          l = u || h || void 0,
          f = "2.0.0",
          d = { connecting: 0, open: 1, closing: 2, closed: 3 },
          p = 1e4,
          v = { closed: "closed", errored: "errored", joined: "joined", joining: "joining", leaving: "leaving" },
          y = { close: "phx_close", error: "phx_error", join: "phx_join", reply: "phx_reply", leave: "phx_leave" },
          m = [y.close, y.error, y.join, y.reply, y.leave],
          g = { longpoll: "longpoll", websocket: "websocket" },
          k = function k(e) {
        if ("function" == typeof e) return e;return function () {
          return e;
        };
      },
          b = function () {
        function e(t, n, i, o) {
          s(this, e), this.channel = t, this.event = n, this.payload = i || function () {
            return {};
          }, this.receivedResp = null, this.timeout = o, this.timeoutTimer = null, this.recHooks = [], this.sent = !1;
        }return c(e, [{ key: "resend", value: function value(e) {
            this.timeout = e, this.reset(), this.send();
          } }, { key: "send", value: function value() {
            this.hasReceived("timeout") || (this.startTimeout(), this.sent = !0, this.channel.socket.push({ topic: this.channel.topic, event: this.event, payload: this.payload(), ref: this.ref, join_ref: this.channel.joinRef() }));
          } }, { key: "receive", value: function value(e, t) {
            return this.hasReceived(e) && t(this.receivedResp.response), this.recHooks.push({ status: e, callback: t }), this;
          } }, { key: "reset", value: function value() {
            this.cancelRefEvent(), this.ref = null, this.refEvent = null, this.receivedResp = null, this.sent = !1;
          } }, { key: "matchReceive", value: function value(e) {
            var t = e.status,
                n = e.response;e.ref;this.recHooks.filter(function (e) {
              return e.status === t;
            }).forEach(function (e) {
              return e.callback(n);
            });
          } }, { key: "cancelRefEvent", value: function value() {
            this.refEvent && this.channel.off(this.refEvent);
          } }, { key: "cancelTimeout", value: function value() {
            clearTimeout(this.timeoutTimer), this.timeoutTimer = null;
          } }, { key: "startTimeout", value: function value() {
            var e = this;this.timeoutTimer && this.cancelTimeout(), this.ref = this.channel.socket.makeRef(), this.refEvent = this.channel.replyEventName(this.ref), this.channel.on(this.refEvent, function (t) {
              e.cancelRefEvent(), e.cancelTimeout(), e.receivedResp = t, e.matchReceive(t);
            }), this.timeoutTimer = setTimeout(function () {
              e.trigger("timeout", {});
            }, this.timeout);
          } }, { key: "hasReceived", value: function value(e) {
            return this.receivedResp && this.receivedResp.status === e;
          } }, { key: "trigger", value: function value(e, t) {
            this.channel.trigger(this.refEvent, { status: e, response: t });
          } }]), e;
      }(),
          j = function () {
        function e(t, n, i) {
          var o = this;s(this, e), this.state = v.closed, this.topic = t, this.params = k(n || {}), this.socket = i, this.bindings = [], this.bindingRef = 0, this.timeout = this.socket.timeout, this.joinedOnce = !1, this.joinPush = new b(this, y.join, this.params, this.timeout), this.pushBuffer = [], this.rejoinTimer = new S(function () {
            o.socket.isConnected() && o.rejoin();
          }, this.socket.rejoinAfterMs), this.socket.onError(function () {
            return o.rejoinTimer.reset();
          }), this.socket.onOpen(function () {
            o.rejoinTimer.reset(), o.isErrored() && o.rejoin();
          }), this.joinPush.receive("ok", function () {
            o.state = v.joined, o.rejoinTimer.reset(), o.pushBuffer.forEach(function (e) {
              return e.send();
            }), o.pushBuffer = [];
          }), this.joinPush.receive("error", function () {
            o.state = v.errored, o.socket.isConnected() && o.rejoinTimer.scheduleTimeout();
          }), this.onClose(function () {
            o.rejoinTimer.reset(), o.socket.hasLogger() && o.socket.log("channel", "close ".concat(o.topic, " ").concat(o.joinRef())), o.state = v.closed, o.socket.remove(o);
          }), this.onError(function (e) {
            o.socket.hasLogger() && o.socket.log("channel", "error ".concat(o.topic), e), o.isJoining() && o.joinPush.reset(), o.state = v.errored, o.socket.isConnected() && o.rejoinTimer.scheduleTimeout();
          }), this.joinPush.receive("timeout", function () {
            o.socket.hasLogger() && o.socket.log("channel", "timeout ".concat(o.topic, " (").concat(o.joinRef(), ")"), o.joinPush.timeout), new b(o, y.leave, k({}), o.timeout).send(), o.state = v.errored, o.joinPush.reset(), o.socket.isConnected() && o.rejoinTimer.scheduleTimeout();
          }), this.on(y.reply, function (e, t) {
            o.trigger(o.replyEventName(t), e);
          });
        }return c(e, [{ key: "join", value: function value() {
            var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : this.timeout;if (this.joinedOnce) throw new Error("tried to join multiple times. 'join' can only be called a single time per channel instance");return this.timeout = e, this.joinedOnce = !0, this.rejoin(), this.joinPush;
          } }, { key: "onClose", value: function value(e) {
            this.on(y.close, e);
          } }, { key: "onError", value: function value(e) {
            return this.on(y.error, function (t) {
              return e(t);
            });
          } }, { key: "on", value: function value(e, t) {
            var n = this.bindingRef++;return this.bindings.push({ event: e, ref: n, callback: t }), n;
          } }, { key: "off", value: function value(e, t) {
            this.bindings = this.bindings.filter(function (n) {
              return !(n.event === e && (void 0 === t || t === n.ref));
            });
          } }, { key: "canPush", value: function value() {
            return this.socket.isConnected() && this.isJoined();
          } }, { key: "push", value: function value(e, t) {
            var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : this.timeout;if (!this.joinedOnce) throw new Error("tried to push '".concat(e, "' to '").concat(this.topic, "' before joining. Use channel.join() before pushing events"));var i = new b(this, e, function () {
              return t;
            }, n);return this.canPush() ? i.send() : (i.startTimeout(), this.pushBuffer.push(i)), i;
          } }, { key: "leave", value: function value() {
            var e = this,
                t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : this.timeout;this.rejoinTimer.reset(), this.joinPush.cancelTimeout(), this.state = v.leaving;var n = function n() {
              e.socket.hasLogger() && e.socket.log("channel", "leave ".concat(e.topic)), e.trigger(y.close, "leave");
            },
                i = new b(this, y.leave, k({}), t);return i.receive("ok", function () {
              return n();
            }).receive("timeout", function () {
              return n();
            }), i.send(), this.canPush() || i.trigger("ok", {}), i;
          } }, { key: "onMessage", value: function value(e, t, n) {
            return t;
          } }, { key: "isLifecycleEvent", value: function value(e) {
            return m.indexOf(e) >= 0;
          } }, { key: "isMember", value: function value(e, t, n, i) {
            return this.topic === e && (!i || i === this.joinRef() || !this.isLifecycleEvent(t) || (this.socket.hasLogger() && this.socket.log("channel", "dropping outdated message", { topic: e, event: t, payload: n, joinRef: i }), !1));
          } }, { key: "joinRef", value: function value() {
            return this.joinPush.ref;
          } }, { key: "sendJoin", value: function value(e) {
            this.state = v.joining, this.joinPush.resend(e);
          } }, { key: "rejoin", value: function value() {
            var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : this.timeout;this.isLeaving() || this.sendJoin(e);
          } }, { key: "trigger", value: function value(e, t, n, i) {
            var o = this.onMessage(e, t, n, i);if (t && !o) throw new Error("channel onMessage callbacks must return the payload, modified or unmodified");for (var r = 0; r < this.bindings.length; r++) {
              var s = this.bindings[r];s.event === e && s.callback(o, n, i || this.joinRef());
            }
          } }, { key: "replyEventName", value: function value(e) {
            return "chan_reply_".concat(e);
          } }, { key: "isClosed", value: function value() {
            return this.state === v.closed;
          } }, { key: "isErrored", value: function value() {
            return this.state === v.errored;
          } }, { key: "isJoined", value: function value() {
            return this.state === v.joined;
          } }, { key: "isJoining", value: function value() {
            return this.state === v.joining;
          } }, { key: "isLeaving", value: function value() {
            return this.state === v.leaving;
          } }]), e;
      }(),
          R = { encode: function encode(e, t) {
          var n = [e.join_ref, e.ref, e.topic, e.event, e.payload];return t(JSON.stringify(n));
        }, decode: function decode(e, t) {
          var n = r(JSON.parse(e), 5);return t({ join_ref: n[0], ref: n[1], topic: n[2], event: n[3], payload: n[4] });
        } },
          C = function () {
        function e(t) {
          var n = this,
              i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};s(this, e), this.stateChangeCallbacks = { open: [], close: [], error: [], message: [] }, this.channels = [], this.sendBuffer = [], this.ref = 0, this.timeout = i.timeout || p, this.transport = i.transport || l.WebSocket || T, this.defaultEncoder = R.encode, this.defaultDecoder = R.decode, this.closeWasClean = !1, this.unloaded = !1, this.binaryType = i.binaryType || "arraybuffer", this.transport !== T ? (this.encode = i.encode || this.defaultEncoder, this.decode = i.decode || this.defaultDecoder) : (this.encode = this.defaultEncoder, this.decode = this.defaultDecoder), h && h.addEventListener && h.addEventListener("beforeunload", function (e) {
            n.conn && (n.unloaded = !0, n.abnormalClose("unloaded"));
          }), this.heartbeatIntervalMs = i.heartbeatIntervalMs || 3e4, this.rejoinAfterMs = function (e) {
            return i.rejoinAfterMs ? i.rejoinAfterMs(e) : [1e3, 2e3, 5e3][e - 1] || 1e4;
          }, this.reconnectAfterMs = function (e) {
            return n.unloaded ? 100 : i.reconnectAfterMs ? i.reconnectAfterMs(e) : [10, 50, 100, 150, 200, 250, 500, 1e3, 2e3][e - 1] || 5e3;
          }, this.logger = i.logger || null, this.longpollerTimeout = i.longpollerTimeout || 2e4, this.params = k(i.params || {}), this.endPoint = "".concat(t, "/").concat(g.websocket), this.vsn = i.vsn || f, this.heartbeatTimer = null, this.pendingHeartbeatRef = null, this.reconnectTimer = new S(function () {
            n.teardown(function () {
              return n.connect();
            });
          }, this.reconnectAfterMs);
        }return c(e, [{ key: "protocol", value: function value() {
            return location.protocol.match(/^https/) ? "wss" : "ws";
          } }, { key: "endPointURL", value: function value() {
            var e = w.appendParams(w.appendParams(this.endPoint, this.params()), { vsn: this.vsn });return "/" !== e.charAt(0) ? e : "/" === e.charAt(1) ? "".concat(this.protocol(), ":").concat(e) : "".concat(this.protocol(), "://").concat(location.host).concat(e);
          } }, { key: "disconnect", value: function value(e, t, n) {
            this.closeWasClean = !0, this.reconnectTimer.reset(), this.teardown(e, t, n);
          } }, { key: "connect", value: function value(e) {
            var t = this;e && (console && console.log("passing params to connect is deprecated. Instead pass :params to the Socket constructor"), this.params = k(e)), this.conn || (this.closeWasClean = !1, this.conn = new this.transport(this.endPointURL()), this.conn.binaryType = this.binaryType, this.conn.timeout = this.longpollerTimeout, this.conn.onopen = function () {
              return t.onConnOpen();
            }, this.conn.onerror = function (e) {
              return t.onConnError(e);
            }, this.conn.onmessage = function (e) {
              return t.onConnMessage(e);
            }, this.conn.onclose = function (e) {
              return t.onConnClose(e);
            });
          } }, { key: "log", value: function value(e, t, n) {
            this.logger(e, t, n);
          } }, { key: "hasLogger", value: function value() {
            return null !== this.logger;
          } }, { key: "onOpen", value: function value(e) {
            this.stateChangeCallbacks.open.push(e);
          } }, { key: "onClose", value: function value(e) {
            this.stateChangeCallbacks.close.push(e);
          } }, { key: "onError", value: function value(e) {
            this.stateChangeCallbacks.error.push(e);
          } }, { key: "onMessage", value: function value(e) {
            this.stateChangeCallbacks.message.push(e);
          } }, { key: "onConnOpen", value: function value() {
            this.hasLogger() && this.log("transport", "connected to ".concat(this.endPointURL())), this.unloaded = !1, this.closeWasClean = !1, this.flushSendBuffer(), this.reconnectTimer.reset(), this.resetHeartbeat(), this.stateChangeCallbacks.open.forEach(function (e) {
              return e();
            });
          } }, { key: "resetHeartbeat", value: function value() {
            var e = this;this.conn && this.conn.skipHeartbeat || (this.pendingHeartbeatRef = null, clearInterval(this.heartbeatTimer), this.heartbeatTimer = setInterval(function () {
              return e.sendHeartbeat();
            }, this.heartbeatIntervalMs));
          } }, { key: "teardown", value: function value(e, t, n) {
            this.conn && (this.conn.onclose = function () {}, t ? this.conn.close(t, n || "") : this.conn.close(), this.conn = null), e && e();
          } }, { key: "onConnClose", value: function value(e) {
            this.hasLogger() && this.log("transport", "close", e), this.triggerChanError(), clearInterval(this.heartbeatTimer), this.closeWasClean || this.reconnectTimer.scheduleTimeout(), this.stateChangeCallbacks.close.forEach(function (t) {
              return t(e);
            });
          } }, { key: "onConnError", value: function value(e) {
            this.hasLogger() && this.log("transport", e), this.triggerChanError(), this.stateChangeCallbacks.error.forEach(function (t) {
              return t(e);
            });
          } }, { key: "triggerChanError", value: function value() {
            this.channels.forEach(function (e) {
              e.isErrored() || e.isLeaving() || e.isClosed() || e.trigger(y.error);
            });
          } }, { key: "connectionState", value: function value() {
            switch (this.conn && this.conn.readyState) {case d.connecting:
                return "connecting";case d.open:
                return "open";case d.closing:
                return "closing";default:
                return "closed";}
          } }, { key: "isConnected", value: function value() {
            return "open" === this.connectionState();
          } }, { key: "remove", value: function value(e) {
            this.channels = this.channels.filter(function (t) {
              return t.joinRef() !== e.joinRef();
            });
          } }, { key: "channel", value: function value(e) {
            var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
                n = new j(e, t, this);return this.channels.push(n), n;
          } }, { key: "push", value: function value(e) {
            var t = this;if (this.hasLogger()) {
              var n = e.topic,
                  i = e.event,
                  o = e.payload,
                  r = e.ref,
                  s = e.join_ref;this.log("push", "".concat(n, " ").concat(i, " (").concat(s, ", ").concat(r, ")"), o);
            }this.isConnected() ? this.encode(e, function (e) {
              return t.conn.send(e);
            }) : this.sendBuffer.push(function () {
              return t.encode(e, function (e) {
                return t.conn.send(e);
              });
            });
          } }, { key: "makeRef", value: function value() {
            var e = this.ref + 1;return e === this.ref ? this.ref = 0 : this.ref = e, this.ref.toString();
          } }, { key: "sendHeartbeat", value: function value() {
            if (this.isConnected()) {
              if (this.pendingHeartbeatRef) return this.pendingHeartbeatRef = null, this.hasLogger() && this.log("transport", "heartbeat timeout. Attempting to re-establish connection"), void this.abnormalClose("heartbeat timeout");this.pendingHeartbeatRef = this.makeRef(), this.push({ topic: "phoenix", event: "heartbeat", payload: {}, ref: this.pendingHeartbeatRef });
            }
          } }, { key: "abnormalClose", value: function value(e) {
            this.closeWasClean = !1, this.conn.close(1e3, e);
          } }, { key: "flushSendBuffer", value: function value() {
            this.isConnected() && this.sendBuffer.length > 0 && (this.sendBuffer.forEach(function (e) {
              return e();
            }), this.sendBuffer = []);
          } }, { key: "onConnMessage", value: function value(e) {
            var t = this;this.decode(e.data, function (e) {
              var n = e.topic,
                  i = e.event,
                  o = e.payload,
                  r = e.ref,
                  s = e.join_ref;r && r === t.pendingHeartbeatRef && (t.pendingHeartbeatRef = null), t.hasLogger() && t.log("receive", "".concat(o.status || "", " ").concat(n, " ").concat(i, " ").concat(r && "(" + r + ")" || ""), o);for (var a = 0; a < t.channels.length; a++) {
                var c = t.channels[a];c.isMember(n, i, o, s) && c.trigger(i, o, r, s);
              }for (var u = 0; u < t.stateChangeCallbacks.message.length; u++) {
                t.stateChangeCallbacks.message[u](e);
              }
            });
          } }]), e;
      }(),
          T = function () {
        function e(t) {
          s(this, e), this.endPoint = null, this.token = null, this.skipHeartbeat = !0, this.onopen = function () {}, this.onerror = function () {}, this.onmessage = function () {}, this.onclose = function () {}, this.pollEndpoint = this.normalizeEndpoint(t), this.readyState = d.connecting, this.poll();
        }return c(e, [{ key: "normalizeEndpoint", value: function value(e) {
            return e.replace("ws://", "http://").replace("wss://", "https://").replace(new RegExp("(.*)/" + g.websocket), "$1/" + g.longpoll);
          } }, { key: "endpointURL", value: function value() {
            return w.appendParams(this.pollEndpoint, { token: this.token });
          } }, { key: "closeAndRetry", value: function value() {
            this.close(), this.readyState = d.connecting;
          } }, { key: "ontimeout", value: function value() {
            this.onerror("timeout"), this.closeAndRetry();
          } }, { key: "poll", value: function value() {
            var e = this;this.readyState !== d.open && this.readyState !== d.connecting || w.request("GET", this.endpointURL(), "application/json", null, this.timeout, this.ontimeout.bind(this), function (t) {
              if (t) {
                var n = t.status,
                    i = t.token,
                    o = t.messages;e.token = i;
              } else n = 0;switch (n) {case 200:
                  o.forEach(function (t) {
                    return e.onmessage({ data: t });
                  }), e.poll();break;case 204:
                  e.poll();break;case 410:
                  e.readyState = d.open, e.onopen(), e.poll();break;case 0:case 500:
                  e.onerror(), e.closeAndRetry();break;default:
                  throw new Error("unhandled poll status ".concat(n));}
            });
          } }, { key: "send", value: function value(e) {
            var t = this;w.request("POST", this.endpointURL(), "application/json", e, this.timeout, this.onerror.bind(this, "timeout"), function (e) {
              e && 200 === e.status || (t.onerror(e && e.status), t.closeAndRetry());
            });
          } }, { key: "close", value: function value(e, t) {
            this.readyState = d.closed, this.onclose();
          } }]), e;
      }(),
          w = function () {
        function e() {
          s(this, e);
        }return c(e, null, [{ key: "request", value: function value(e, t, n, i, o, r, s) {
            if (l.XDomainRequest) {
              var a = new XDomainRequest();this.xdomainRequest(a, e, t, i, o, r, s);
            } else {
              var c = l.XMLHttpRequest ? new l.XMLHttpRequest() : new ActiveXObject("Microsoft.XMLHTTP");this.xhrRequest(c, e, t, n, i, o, r, s);
            }
          } }, { key: "xdomainRequest", value: function value(e, t, n, i, o, r, s) {
            var a = this;e.timeout = o, e.open(t, n), e.onload = function () {
              var t = a.parseJSON(e.responseText);s && s(t);
            }, r && (e.ontimeout = r), e.onprogress = function () {}, e.send(i);
          } }, { key: "xhrRequest", value: function value(e, t, n, i, o, r, s, a) {
            var c = this;e.open(t, n, !0), e.timeout = r, e.setRequestHeader("Content-Type", i), e.onerror = function () {
              a && a(null);
            }, e.onreadystatechange = function () {
              if (e.readyState === c.states.complete && a) {
                var t = c.parseJSON(e.responseText);a(t);
              }
            }, s && (e.ontimeout = s), e.send(o);
          } }, { key: "parseJSON", value: function value(e) {
            if (!e || "" === e) return null;try {
              return JSON.parse(e);
            } catch (t) {
              return console && console.log("failed to parse JSON response", e), null;
            }
          } }, { key: "serialize", value: function value(e, t) {
            var n = [];for (var i in e) {
              if (e.hasOwnProperty(i)) {
                var r = t ? "".concat(t, "[").concat(i, "]") : i,
                    s = e[i];"object" === o(s) ? n.push(this.serialize(s, r)) : n.push(encodeURIComponent(r) + "=" + encodeURIComponent(s));
              }
            }return n.join("&");
          } }, { key: "appendParams", value: function value(e, t) {
            if (0 === Object.keys(t).length) return e;var n = e.match(/\?/) ? "&" : "?";return "".concat(e).concat(n).concat(this.serialize(t));
          } }]), e;
      }();w.states = { complete: 4 };var E = function () {
        function e(t) {
          var n = this,
              i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};s(this, e);var o = i.events || { state: "presence_state", diff: "presence_diff" };this.state = {}, this.pendingDiffs = [], this.channel = t, this.joinRef = null, this.caller = { onJoin: function onJoin() {}, onLeave: function onLeave() {}, onSync: function onSync() {} }, this.channel.on(o.state, function (t) {
            var i = n.caller,
                o = i.onJoin,
                r = i.onLeave,
                s = i.onSync;n.joinRef = n.channel.joinRef(), n.state = e.syncState(n.state, t, o, r), n.pendingDiffs.forEach(function (t) {
              n.state = e.syncDiff(n.state, t, o, r);
            }), n.pendingDiffs = [], s();
          }), this.channel.on(o.diff, function (t) {
            var i = n.caller,
                o = i.onJoin,
                r = i.onLeave,
                s = i.onSync;n.inPendingSyncState() ? n.pendingDiffs.push(t) : (n.state = e.syncDiff(n.state, t, o, r), s());
          });
        }return c(e, [{ key: "onJoin", value: function value(e) {
            this.caller.onJoin = e;
          } }, { key: "onLeave", value: function value(e) {
            this.caller.onLeave = e;
          } }, { key: "onSync", value: function value(e) {
            this.caller.onSync = e;
          } }, { key: "list", value: function value(t) {
            return e.list(this.state, t);
          } }, { key: "inPendingSyncState", value: function value() {
            return !this.joinRef || this.joinRef !== this.channel.joinRef();
          } }], [{ key: "syncState", value: function value(e, t, n, i) {
            var o = this,
                r = this.clone(e),
                s = {},
                a = {};return this.map(r, function (e, n) {
              t[e] || (a[e] = n);
            }), this.map(t, function (e, t) {
              var n = r[e];if (n) {
                var i = t.metas.map(function (e) {
                  return e.phx_ref;
                }),
                    c = n.metas.map(function (e) {
                  return e.phx_ref;
                }),
                    u = t.metas.filter(function (e) {
                  return c.indexOf(e.phx_ref) < 0;
                }),
                    h = n.metas.filter(function (e) {
                  return i.indexOf(e.phx_ref) < 0;
                });u.length > 0 && (s[e] = t, s[e].metas = u), h.length > 0 && (a[e] = o.clone(n), a[e].metas = h);
              } else s[e] = t;
            }), this.syncDiff(r, { joins: s, leaves: a }, n, i);
          } }, { key: "syncDiff", value: function value(e, t, n, o) {
            var r = t.joins,
                s = t.leaves,
                a = this.clone(e);return n || (n = function n() {}), o || (o = function o() {}), this.map(r, function (e, t) {
              var o = a[e];if (a[e] = t, o) {
                var r,
                    s = a[e].metas.map(function (e) {
                  return e.phx_ref;
                }),
                    c = o.metas.filter(function (e) {
                  return s.indexOf(e.phx_ref) < 0;
                });(r = a[e].metas).unshift.apply(r, i(c));
              }n(e, o, t);
            }), this.map(s, function (e, t) {
              var n = a[e];if (n) {
                var i = t.metas.map(function (e) {
                  return e.phx_ref;
                });n.metas = n.metas.filter(function (e) {
                  return i.indexOf(e.phx_ref) < 0;
                }), o(e, n, t), 0 === n.metas.length && delete a[e];
              }
            }), a;
          } }, { key: "list", value: function value(e, t) {
            return t || (t = function t(e, _t) {
              return _t;
            }), this.map(e, function (e, n) {
              return t(e, n);
            });
          } }, { key: "map", value: function value(e, t) {
            return Object.getOwnPropertyNames(e).map(function (n) {
              return t(n, e[n]);
            });
          } }, { key: "clone", value: function value(e) {
            return JSON.parse(JSON.stringify(e));
          } }]), e;
      }(),
          S = function () {
        function e(t, n) {
          s(this, e), this.callback = t, this.timerCalc = n, this.timer = null, this.tries = 0;
        }return c(e, [{ key: "reset", value: function value() {
            this.tries = 0, clearTimeout(this.timer);
          } }, { key: "scheduleTimeout", value: function value() {
            var e = this;clearTimeout(this.timer), this.timer = setTimeout(function () {
              e.tries = e.tries + 1, e.callback();
            }, this.timerCalc(this.tries + 1));
          } }]), e;
      }();
    }]);
  });
});