define("ember-cli-meta-tags/templates/components/head-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j5ox95ZC",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"  \"],[8,[39,2],null,[[\"@headTag\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null]],[\"@headTags\",\"headTag\"],false,[\"each\",\"-track-array\",\"head-tag\"]]",
    "moduleName": "ember-cli-meta-tags/templates/components/head-tags.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});