define("ember-component-css/pod-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "account/billing/billing-main": "__3f1a6",
    "account/billing/checkout-main": "__d645f",
    "account/billing/checkout/order-summary": "__ad9eb",
    "account/billing/invoice": "__c9516",
    "account/billing/plans-main": "__bb4b1",
    "account/login-card": "__20ef8",
    "account/my-account": "__8497b",
    "account/my-account/account-main": "__b3fdf",
    "account/my-account/change-password-main": "__e3b3d",
    "account/my-account/delete-request": "__c3db0",
    "account/organization-settings": "__44626",
    "account/seats/seats-main": "__8c64a",
    "account/seats/user-item": "__b1a71",
    "add-time/row": "__574e2",
    "artifact-group-handle": "__a5140",
    "client-form": "__90853",
    "client-list": "__190f9",
    "color-box": "__6eca9",
    "dashboard/project-card": "__96507",
    "dashboard/project-dashboard-list": "__04a35",
    "dashboard/project": "__fc10c",
    "day/activity-alert": "__d43c9",
    "day/artifact-display": "__5307a",
    "day/browsing-group-artifact": "__b23b2",
    "day/browsing-group": "__3d359",
    "day/calendar/artifact-group-details": "__9c5f3",
    "day/calendar/artifact-group-details/checkbox": "__1521d",
    "day/calendar/artifact-group": "__8240e",
    "day/calendar/artifact-group/artifact-group-details/group-color": "__35285",
    "day/calendar/calendar-view": "__a761f",
    "day/calendar/project-column-sum": "__3acce",
    "day/calendar/project-comments": "__d5d4f",
    "day/calendar/project-details-popover": "__f97b6",
    "day/calendar/project-task-type": "__5a724",
    "day/calendar/time-line-side-bar": "__a35b6",
    "day/calendar/time-line": "__ec39b",
    "day/calendar/timesheet/timesheet-entry": "__e1621",
    "day/calendar/timesheet/timesheet-preview": "__2f4e6",
    "day/calendar/unallocated-time": "__5e222",
    "day/calendar/web-activity": "__1b2bc",
    "day/day-view-main": "__899a2",
    "day/header/day-breakdown-project": "__5a31d",
    "day/header/tool-bar": "__6c0d7",
    "day/list/artifact/artifact-list-item": "__07e34",
    "day/list/border-bar-bottom": "__66a38",
    "day/list/border-bar-top": "__3af79",
    "day/list/list-view": "__afcf2",
    "day/out-of-range": "__01609",
    "day/popover-file": "__af937",
    "day/project-assign-small": "__e8c62",
    "day/svg-position": "__ed0c5",
    "day/timesheet-review/project-length": "__b6ce1",
    "day/web-activity-graph/confirm-url-training": "__dadb2",
    "debugger": "__6cfd3",
    "debugger/websockets": "__13d96",
    "divider": "__c72fa",
    "feature-guard": "__81241",
    "forgot-password-card": "__d6458",
    "integration-actions": "__eea9f",
    "integration-listings/integration-listing": "__98863",
    "integration-listings/integration-listing/oauth-application": "__53e23",
    "integration-modal": "__b8192",
    "integrations-main": "__e52b9",
    "intercom-box": "__c3281",
    "invite-friends/invite-a-friend-main": "__28d66",
    "keyboard-shortcuts-modal": "__0366c",
    "layouts/project-item": "__a9136",
    "manage-client": "__a548c",
    "manage-project": "__0b5ff",
    "manage/clients-main": "__0e6ee",
    "manage/projects/edit-main": "__58dd2",
    "navbar/account-dropdown": "__eee51",
    "navbar/alert": "__64891",
    "navbar/alert/onboarding-progress": "__58e32",
    "navbar/desktop-nav": "__07c50",
    "navbar/mobile-nav": "__bcaa9",
    "new-project": "__f6db8",
    "onboarding/clockk-appsumo": "__3f070",
    "onboarding/download-main": "__71bb2",
    "onboarding/download/phase": "__88c9d",
    "onboarding/first-click": "__983d0",
    "onboarding/step-congratulations": "__81a77",
    "onboarding/tutorial-video-box": "__8f91b",
    "page-header": "__1478b",
    "project-breakdown-circle-popover-single": "__b87d8",
    "project-breakdown-circle-popover": "__41408",
    "project-breakdown-circle": "__b444b",
    "project-form": "__94c66",
    "project-name-display-tab": "__4337f",
    "project-name-display": "__d2462",
    "project-select": "__74b34",
    "reports/basic/basic-report-bottom": "__103dc",
    "reports/basic/basic-report-top": "__ea2d1",
    "reports/basic/basic-report": "__3ac76",
    "reports/detailed/detailed-main": "__d1eec",
    "reports/detailed/entry-popover": "__2ee29",
    "reports/detailed/table-body-cell": "__c7b89",
    "reports/detailed/table-footer-cell": "__a71cc",
    "reports/detailed/table-header-cell": "__1ac0c",
    "reports/reports-main": "__9633d",
    "reports/timesheet-filter": "__1c6ab",
    "setup/mobile/onboarding-main": "__031c7",
    "setup/mobile/onboarding/testimonies": "__fca5e",
    "sign-up/2-column-template": "__f7fa2",
    "sign-up/customer-options": "__18299",
    "sign-up/downloads": "__656c5",
    "sign-up/first-projects": "__ea83f",
    "sign-up/testimony": "__4a9aa",
    "sign-up/tool-types": "__8a00b",
    "sign-up/user-info": "__0d104",
    "slide-selector": "__50204",
    "states/loading": "__94dc7",
    "task-type-pill": "__da1bb",
    "task-types/task-type": "__573ae",
    "timeline-filter": "__8daf3",
    "timesheets/footer-bar": "__b60ef",
    "timesheets/header-nav": "__18be6",
    "timesheets/personal/list": "__51d44",
    "timesheets/personal/personal-main": "__7ff9c",
    "timesheets/personal/project-item": "__ec2ba",
    "timesheets/team/list": "__a4bb2",
    "timesheets/team/range-dropdown": "__ff3e3",
    "timesheets/team/team-main": "__059b0",
    "timesheets/team/user-item": "__86114",
    "tracking/box-item": "__a2857",
    "tracking/browsers": "__84b8f",
    "tracking/calendar-modal": "__16505",
    "tracking/calendars": "__adf96",
    "tracking/email-modal": "__f9dd4",
    "tracking/emails": "__b7939",
    "tracking/extensions": "__1cf9a",
    "trial-time-remaining": "__cc627",
    "tutorial-videos/tutorial-videos-main": "__ce60a"
  };
  _exports.default = _default;
});