define("@adopted-ember-addons/ember-stripe-elements/utils/load-script", ["exports", "@ember/runloop", "rsvp"], function (_exports, _runloop, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = loadScript;

  /*
   * loadScript will load a JavaScript asset. Subsequent load
   * calls with a already requested URL will just chain off the
   * already existing promise.
   */
  var loadedScripts = {};

  function loadScript(url) {
    var promise = loadedScripts[url];

    if (!promise) {
      promise = new _rsvp.default.Promise(function (resolve, reject) {
        var element = document.createElement('script');
        element.type = 'text/javascript';
        element.async = false;
        element.addEventListener('load', function () {
          (0, _runloop.run)(function () {
            resolve();
          });
        }, false);
        element.addEventListener('error', function () {
          var error = new Error("Could not load script ".concat(url));
          (0, _runloop.run)(function () {
            reject(error);
          });
        }, false);
        element.src = url;
        var firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode.insertBefore(element, firstScript);
      });
      loadedScripts[url] = promise;
    }

    return promise;
  }
});