define("ember-bootstrap/utils/transition-end", ["exports", "ember", "@ember/runloop", "rsvp"], function (_exports, _ember, _runloop, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.skipTransition = skipTransition;
  _exports.default = waitForTransitionEnd;

  var _skipTransition;

  function skipTransition(bool) {
    _skipTransition = bool;
  }

  function _isSkipped() {
    return _skipTransition === true | _skipTransition !== false && _ember.default.testing;
  }

  function waitForTransitionEnd(node) {
    var duration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

    if (!node) {
      return (0, _rsvp.reject)();
    }

    var backup;

    if (_isSkipped()) {
      duration = 0;
    }

    return new _rsvp.Promise(function (resolve) {
      var done = function done() {
        if (backup) {
          (0, _runloop.cancel)(backup);
          backup = null;
        }

        node.removeEventListener('transitionend', done);
        resolve();
      };

      node.addEventListener('transitionend', done, false);
      backup = (0, _runloop.later)(this, done, duration);
    });
  }
});