define("ember-cli-meta-tags/templates/components/head-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/E94VKOT",
    "block": "[[[1,[30,1,[\"content\"]]],[1,\"\\n\"]],[\"@headTag\"],false,[]]",
    "moduleName": "ember-cli-meta-tags/templates/components/head-tag.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});