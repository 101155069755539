define("ember-ref-bucket/utils/ref", ["exports", "@ember/destroyable", "@glimmer/tracking"], function (_exports, _destroyable, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setGlobalRef = setGlobalRef;
  _exports.resolveGlobalRef = resolveGlobalRef;
  _exports.getNodeDestructors = getNodeDestructors;
  _exports.registerNodeDestructor = registerNodeDestructor;
  _exports.unregisterNodeDestructor = unregisterNodeDestructor;
  _exports.bucketFor = bucketFor;
  _exports.watchFor = watchFor;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var lastGlobalRef = null;
  var buckets = new WeakMap();
  var nodeDestructors = new WeakMap();
  var FieldCell = (_class = function FieldCell() {
    _classCallCheck(this, FieldCell);

    _initializerDefineProperty(this, "value", _descriptor, this);
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  })), _class);

  function setGlobalRef(value) {
    lastGlobalRef = value;
  }

  function resolveGlobalRef() {
    return lastGlobalRef;
  }

  function createBucket() {
    return {
      bucket: {},
      keys: {},
      createTrackedCell: function createTrackedCell(key) {
        if (!(key in this.keys)) {
          this.keys[key] = new FieldCell();
        }
      },
      get: function get(name) {
        this.createTrackedCell(name);
        return this.bucket[name] || null;
      },
      dirtyTrackedCell: function dirtyTrackedCell(name) {
        this.createTrackedCell(name);
        var val = this.keys[name].value;
        this.keys[name].value = val;
      },
      getTracked: function getTracked(name) {
        this.createTrackedCell(name);
        return this.keys[name].value;
      },
      add: function add(name, value) {
        this.createTrackedCell(name);
        this.keys[name].value = value;
        this.bucket[name] = value;

        if (!(name in this.notificationsFor)) {
          this.notificationsFor[name] = [];
        }

        this.notificationsFor[name].forEach(function (fn) {
          return fn();
        });
      },
      addNotificationFor: function addNotificationFor(name, fn) {
        var _this = this;

        if (!(name in this.notificationsFor)) {
          this.notificationsFor[name] = [];
        }

        this.notificationsFor[name].push(fn);
        return function () {
          _this.notificationsFor[name] = _this.notificationsFor[name].filter(function (cb) {
            return cb !== cb;
          });
        };
      },
      notificationsFor: {}
    };
  }

  function getNodeDestructors(node) {
    return nodeDestructors.get(node) || [];
  }

  function registerNodeDestructor(node, cb) {
    if (!nodeDestructors.has(node)) {
      nodeDestructors.set(node, []);
    }

    nodeDestructors.get(node).push(cb);
  }

  function unregisterNodeDestructor(node, cb) {
    var destructors = nodeDestructors.get(node) || [];
    nodeDestructors.set(node, destructors.filter(function (el) {
      return el !== cb;
    }));
  }

  function bucketFor(rawCtx) {
    var ctx = rawCtx;

    if (!buckets.has(ctx)) {
      buckets.set(ctx, createBucket());

      if ((0, _destroyable.isDestroyed)(ctx) || (0, _destroyable.isDestroying)(ctx)) {
        try {
          return buckets.get(ctx);
        } finally {
          buckets.delete(ctx);
        }
      }

      (0, _destroyable.registerDestructor)(ctx, function () {
        buckets.delete(ctx);
      });
    }

    return buckets.get(ctx);
  }

  function watchFor(name, bucketRef, cb) {
    var bucket = bucketFor(bucketRef);
    return bucket.addNotificationFor(name, cb);
  }
});