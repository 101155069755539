define("ember-initials/components/image/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "AMpXROjX",
    "block": "[[[11,\"img\"],[17,1],[16,0,[30,0,[\"class\"]]],[16,\"width\",[30,0,[\"width\"]]],[16,\"height\",[30,0,[\"height\"]]],[16,\"src\",[30,0,[\"src\"]]],[16,\"alt\",[30,0,[\"alt\"]]],[16,\"title\",[30,0,[\"title\"]]],[16,\"onError\",[30,0,[\"onError\"]]],[12],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "ember-initials/components/image/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});