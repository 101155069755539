define("ember-intercom-io/mixins/intercom-route", ["exports", "@ember/object/mixin", "@ember/service", "@ember/object/evented", "@ember/object", "@ember/debug"], function (_exports, _mixin, _service, _evented, _object, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    intercom: (0, _service.inject)(),

    /**
     * push page changes to intercom
     * @private
     * @on didTransition
     */
    submitRouteChange: (0, _evented.on)('didTransition', function () {
      var intercom = (0, _object.get)(this, 'intercom');
      (false && !(intercom) && (0, _debug.assert)('Could not find property configured intercom instance', intercom));

      if ((0, _object.get)(intercom, 'isBooted')) {
        intercom.update();
      }
    })
  });

  _exports.default = _default;
});